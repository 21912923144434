import React, { useState, useEffect } from 'react';
import useAPI from '../../utils/useApi';
import { APIRequests } from '../../config/api';
import { Form, FieldConfig } from '../../atoms/Form';
import {
  RequestInput,
  CreateFormProps,
  JamyrCookies,
} from '../../types/config';
import { toast } from 'react-toastify';
import { setCookie } from '../../utils/global';

export const EmployeeCreate = (props: CreateFormProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const fields: FieldConfig[] = [
    {
      name: 'first_name',
      label: 'First Name',
      type: 'text',
      validation: { required: true, errormessage: 'First name is required' },
      columnSize: 12,
    },
    {
      name: 'last_name',
      label: 'Last Name',
      type: 'text',
      validation: { required: true, errormessage: 'Last name is required' },
      columnSize: 12,
    },
    {
      name: 'pronouns',
      label: 'Pronouns',
      type: 'text',
      columnSize: 12,
    },
    {
      name: 'email',
      label: 'Work Email Address',
      type: 'text',
      validation: { required: true, errormessage: 'Email is required' },
      columnSize: 12,
    },
    {
      name: 'title',
      label: 'Job Title',
      type: 'text',
      validation: { required: true, errormessage: 'Job Title is required' },
      columnSize: 12,
    },
  ];
  const createApi = useAPI(APIRequests.EmployeeCreate);

  useEffect(() => {
    if (createApi.data) {
      toast('🦄 Info Saved!');
      props.onComplete();
      setLoading(false);
    }
    if (createApi.error) {
      toast.error(String(createApi.error));
      setLoading(false);
    }
  }, [createApi.data, createApi.error]);

  const handleSubmit = (data: any) => {
    setLoading(true);
    data['customer_id'] = props.data.customer_id;
    const req: RequestInput = {
      body: data,
      pathParams: { customer_id: props.data.customer_id },
    };
    createApi.execute(req);
    const cookiedata = Object.values(data).join('*&');
    setCookie(JamyrCookies.EMPLOYEE, cookiedata, 365);
  };

  return (
    <Form
      title="Complete Form"
      submitLabel="Submit"
      fields={fields}
      callback={handleSubmit}
      isLoading={loading}
    />
  );
};
