import React from 'react';
import './index.css';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import {
  Admins,
  Analytics,
  Campaigns,
  CampaignDetail,
  CampaignCreateFlow,
  CollectionPage,
  Collections,
  CollectionDetail,
  Customers,
  ForgotPassword,
  Jobs,
  JobPage,
  LandingPage,
  Login,
  Requests,
  Responses,
  Settings,
  SetPassword,
  Studio,
  Questions,
  UserAdmins,
  Videos,
  VideoDetail,
  VideoEmbed,
} from './views';
import { Redirect } from './utils/Redirect';
import { RequireAuth } from './utils/RequireAuth';
import { AdminNavigation, Navigation } from './components';
import { LoginCallback, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

const oktaAuth = new OktaAuth({
  issuer: 'https://recruitics.oktapreview.com/oauth2/default',
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/okta/login',
});

console.log(process.env.REACT_APP_OKTA_CLIENT_ID);

function App(): JSX.Element {
  const history = useNavigate();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history(toRelativeUrl(originalUri, window.location.origin), {
      replace: true,
    });
  };

  const viewSwitch = (
    <Routes>
      <Route path="/job/:location/:title" element={<JobPage />} />
      <Route path="/jobs" element={<Jobs />} />
      <Route path="/video/jamcoll" element={<Redirect />} />
      <Route path="/video/video-guide" element={<Redirect />} />
      <Route path="/video/jamone" element={<Redirect />} />
      <Route path="/video/jamats" element={<Redirect />} />
      <Route path="/video/jamtrans" element={<Redirect />} />
      <Route path="/video/jamland" element={<Redirect />} />
      <Route path="/video/jobguide" element={<Redirect />} />
      <Route path="/video/jamjobembed" element={<Redirect />} />
      <Route path="/video/jamlandembed" element={<Redirect />} />
      <Route path="/video/embed/:videoId" element={<VideoEmbed />} />
      <Route path="/responses" element={<Redirect />} />
      <Route path="authenticate" element={<SetPassword />} />
      <Route path="reset-password" element={<ForgotPassword />} />
      <Route path="login" element={<Login />} />
      <Route path="/okta/login" element={<LoginCallback />} />
      <Route
        path="admin"
        element={
          <RequireAuth>
            <AdminNavigation />
          </RequireAuth>
        }
      >
        <Route path="users" element={<Admins />} />
        <Route path="customers" element={<Customers />} />
        <Route path="edit-requests" element={<Requests />} />
      </Route>
      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <Navigation />
          </RequireAuth>
        }
      >
        <Route path="campaigns" element={<Campaigns />} />
        <Route path="campaigns/create" element={<CampaignCreateFlow />} />
        <Route path="campaigns/:campaignId" element={<CampaignDetail />} />
        <Route path="admins" element={<UserAdmins />} />
        <Route path="settings" element={<Settings />} />
        <Route path="studio" element={<Studio />} />
        <Route path="questions" element={<Questions />} />
        <Route path="videos" element={<Videos />} />
        <Route path="videos/:videoId" element={<VideoDetail />} />
        <Route path="collections" element={<Collections />} />
        <Route
          path="collections/:collectionId"
          element={<CollectionDetail />}
        />
        <Route path="analytics" element={<Analytics />} />
      </Route>
      <Route path="video/:pathId" element={<LandingPage />} />
      <Route path="collection/:pathId" element={<CollectionPage />} />
      <Route path="campaign/:campaignId" element={<Responses />} />
      <Route
        path="*"
        element={<Navigate to="/dashboard/questions" replace />}
      />
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {viewSwitch}
    </Security>
  );
}

export default App;
