import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './config/api/api';
import { createRoot } from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material';
import './index.css';
import App from './App';
import { GlobalStateProvider } from './utils/GlobalStateProvider';
import ModalProvider from 'mui-modal-provider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router } from 'react-router-dom';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    bold: React.CSSProperties;
    light: React.CSSProperties;
    mono: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bold?: React.CSSProperties;
    light?: React.CSSProperties;
    mono?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bold: true;
    light: true;
    mono: true;
  }
}

const theme = createTheme({
  palette: {
    background: {
      default: '#F7F7FB',
    },
    primary: {
      main: '#34344A',
    },
    secondary: {
      main: '#FFF',
    },
    info: {
      main: '#5352ED',
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif;',
    fontSize: 14,
    // light: {
    //   "fontFamily":  "nb_international_prolight",
    // },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#34344A',
          color: 'white',
          '&:hover': {
            backgroundColor: '#34344A',
            color: 'white',
          },
          textTransform: 'none',
        },
      },
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <GlobalStateProvider>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <ModalProvider>
          <Router>
            <App />
          </Router>
          <ToastContainer />
        </ModalProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </GlobalStateProvider>
);
