import { TrackEvent, JamyrCookies } from '../types/config';

const domain = window.location.href;
const apiDomain = process.env.REACT_APP_API_URL;
const debug: boolean = domain !== 'https://app.jamyr.com';
let external_userid: string | undefined;
let rxTags: { [name: string]: string } | undefined = {};

export function log(f: any, m: string) {
  if (debug) {
    console.log(f, m);
  }
}

export function logError(script: any, error: any) {
  console.error(script, error);
}

export function getUrlVars(item: string) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(item);
}

export function getCookie(name: string) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return undefined;
}

export function eraseCookie(name: string) {
  document.cookie = name + '=; Max-Age=-99999999; path=/';
}

export function setCookie(name: string, value: any, days: number) {
  eraseCookie(name);
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

function identify() {
  return new Promise((resolve, reject) => {
    external_userid = getCookie(JamyrCookies.ID);
    if (!external_userid) {
      const req = new XMLHttpRequest();
      req.onreadystatechange = function () {
        if (req.readyState === 4) {
          const res = JSON.parse(req.response);
          external_userid = res['id'];
          document.cookie = JamyrCookies.ID + '=' + external_userid;
          resolve(undefined);
        }
      };
      req.open('GET', `${apiDomain}/analytics/identify`);
      req.send('');
    } else {
      resolve(undefined);
    }
  });
}

const findRxTags = () => {
  try {
    //@ts-ignore
    if (window.rx) {
      //@ts-ignore
      Object.entries(window.rx._.payload).forEach((k: string) => {
        //@ts-ignore
        rxTags[`rx-js-${k[0]}`] = k[1];
      });

    }
    const images = document.querySelectorAll('img[src*="recruitics.com"]');
    images.forEach((image) => {
      const s = image.getAttribute('src');
      if (s && s.includes('.gif')) {
        const data = s.split('.com')[1].split('.gif');
        const qParamsList = data[1].replace('?', '').split('&');
        const qParams = { 'rx-pixel-source': s }
        qParamsList.forEach((p) => {
          const kv = p.split('=');
          // @ts-ignore
          qParams[`rx-pixel-${kv[0]}`] = kv[1];
        })
        rxTags = { ...rxTags, ...qParams };
      }
    })
  } catch {
    console.error('error retrieving rx data');
  }
}

export async function track(event: string, company: string, data: any) {
  if (!external_userid) {
    await identify();
  }
  // @ts-ignore
  if (Object.keys(rxTags).length === 0) {
    findRxTags();
  }
  const req = new XMLHttpRequest();
  const loc = window.location !== window.parent.location ? window.location.hostname + window.location.pathname : window.parent.location.hostname + window.parent.location.pathname
  let body: TrackEvent = {
    identity: external_userid || '',
    event: event,
    location: loc,
    customer_id: Number(company),
    data: JSON.stringify({ ...data, ...rxTags }),
  };

  req.open('POST', `${apiDomain}/analytics/track`);
  req.setRequestHeader('Content-Type', 'application/json');
  req.send(JSON.stringify(body));
}

export function isMobile() {
  var check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);
  return check;
}

export function getPlatform() {
  const andriod = navigator.userAgent.toLowerCase().match(/android\s([0-9\.]*)/i);
  if ("platform" in navigator && "appVersion" in navigator) {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      let v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
      if (v) {
        return {
          platform: 'ios',
          status: true,
          version: parseInt(v[1], 10),
          info: parseInt(v[1], 10) + '.' + parseInt(v[2], 10) + '.' + parseInt(v[3] || "0", 10)
        };
      }
    } else if (andriod) {
      return { platform: 'andriod', status: true, version: parseInt(andriod[1], 10), info: '' }
    } else {
      return { platform: null, status: false, version: false, info: '' }
    }
  } else {
    return { platform: null, status: false, version: false, info: "platform or appVersion not available" }
  }
}
