import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { CustomIcon, ProgressBar } from '../atoms';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  MediaRecorder as ExtendableMediaRecorder,
  IMediaRecorder,
} from 'extendable-media-recorder';
import { S3_BUCKET, AWS_REGION } from '../config/index';
import AWS from 'aws-sdk';
import { toast } from 'react-toastify';
import { StatusMessages, MediaDevice } from '../types/recorder';
import { isMobile, getPlatform } from '../utils/global';

export interface WebRecorderProps {
  onDelete: () => void;
  onSuccess: (file: string) => void; // after file uploads to S3
}

interface VideoBlobData {
  url: string | undefined;
  blob: Blob | undefined;
}

const platform = getPlatform();
const platformOutofDate =
  platform?.platform &&
  ((platform.platform === 'ios' && platform.version < 16) ||
    (platform.platform === 'andriod' && platform.version < 12));

export const WebRecorder = (props: WebRecorderProps) => {
  const [status, setStatus] = useState<StatusMessages>('idle');
  const [mediaBlob, setMediaBlob] = useState<VideoBlobData>();
  const [selectedAudio, setSelectedAudio] = useState<string>('');
  const [selectedVideo, setSelectedVideo] = useState<string>('');
  const [devices, setDevices] = useState<{ [name: string]: MediaDevice[] }>({
    audio: [],
    video: [],
  });
  const mediaStream = useRef<MediaStream | null>(null);
  const mediaRecorder = useRef<IMediaRecorder | null>(null);
  const mediaChunks = useRef<Blob[]>([]);
  const [countdown, setCountdown] = useState<number>(4);
  const [recordingLength, setRecordingLength] = useState<number>(0);
  const [progress, setProgress] = useState<number>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>();

  const requiredMedia: MediaStreamConstraints = {
    audio: { deviceId: selectedAudio },
    video: {
      height: { min: 576, ideal: 720, max: 1080 },
      width: { min: 1024, ideal: 1280, max: 1920 },
      deviceId: selectedVideo,
    },
  };

  useEffect(() => {
    window.navigator.mediaDevices.getUserMedia(requiredMedia).then((stream) => {
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        mediaStream.current = stream;
        setStatus('idle');
      }
    });
  }, [mediaStream.current, selectedAudio, selectedVideo]);

  const renderVideoPreview = useMemo(() => {
    return (
      <div style={{ width: 'auto', height: 'auto', position: 'relative' }}>
        {!isMobile() && (
          <div
            style={{
              height: '99%',
              width: '25%',
              maxWidth: '25%',
              backgroundColor: 'black',
              opacity: '0.6',
              position: 'absolute',
            }}
          ></div>
        )}
        <video
          ref={videoRef}
          width={'100%'}
          height={'100%'}
          autoPlay
          muted
          webkit-playsinline="true"
          playsInline
        />
        {!isMobile() && (
          <div
            style={{
              height: '99%',
              width: '25%',
              maxWidth: '25%',
              backgroundColor: 'black',
              opacity: '0.6',
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          ></div>
        )}
      </div>
    );
  }, [selectedAudio, selectedVideo]);

  const getMediaStream = useCallback(async () => {
    setStatus('acquiring_media');

    try {
      const stream = await window.navigator.mediaDevices.getUserMedia(
        requiredMedia
      );
      await navigator.mediaDevices.enumerateDevices().then((devices) => {
        const audios = devices.filter((device) => device.kind === 'audioinput');
        const videos = devices.filter((device) => device.kind === 'videoinput');
        setDevices({ audio: audios, video: videos });
      });
      mediaStream.current = stream;
      setStatus('idle');
    } catch (error: any) {
      console.error(error.name);
      setStatus('idle');
    }
  }, []);

  useEffect(() => {
    if (!window.MediaRecorder) {
      throw new Error('Unsupported Browser');
    }
    getMediaStream();
  }, [getMediaStream]);

  // Media Recorder Handlers

  const startRecording = async () => {
    if (!mediaStream.current) {
      await getMediaStream();
    }
    if (mediaStream.current) {
      const isStreamEnded = mediaStream.current
        .getTracks()
        .some((track) => track.readyState === 'ended');
      if (isStreamEnded) {
        await getMediaStream();
      }

      // User blocked the permissions (getMediaStream errored out)
      if (!mediaStream.current.active) {
        return;
      }
      mediaRecorder.current = new ExtendableMediaRecorder(
        mediaStream.current,
        undefined
      );
      mediaRecorder.current.ondataavailable = onRecordingActive;
      mediaRecorder.current.onstop = onRecordingStop;
      mediaRecorder.current.onerror = () => {
        console.error('NO_RECORDER');
        setStatus('idle');
      };
      mediaRecorder.current.start();
      setStatus('recording');
    }
  };

  const onRecordingActive = ({ data }: BlobEvent) => {
    mediaChunks.current.push(data);
  };

  const onRecordingStop = () => {
    const [chunk] = mediaChunks.current;
    const blobProperty: BlobPropertyBag = Object.assign(
      { type: chunk.type },
      { type: 'video/mp4' }
    );
    const blob = new Blob(mediaChunks.current, blobProperty);
    const url = URL.createObjectURL(blob);
    setStatus('stopped');
    setMediaBlob({ url: url, blob: blob });
  };

  const muteAudio = (mute: boolean) => {
    if (mediaStream.current) {
      mediaStream.current
        .getAudioTracks()
        .forEach((audioTrack) => (audioTrack.enabled = !mute));
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      setStatus('paused');
      mediaRecorder.current.pause();
    }
  };
  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'paused') {
      setStatus('recording');
      mediaRecorder.current.resume();
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      if (mediaRecorder.current.state !== 'inactive') {
        setStatus('stopping');
        mediaRecorder.current.stop();
        mediaChunks.current = [];
      }
    }
  };

  useEffect(() => {
    if (status === 'recording') {
      const timer = setTimeout(
        () => setRecordingLength(recordingLength + 1),
        1000
      );
      setTimer(timer);
    } else if (['paused', 'stopped'].includes(status) && timer) {
      clearTimeout(timer);
      setTimer(null);
    }
  }, [status, recordingLength]);

  useEffect(() => {
    if (countdown !== 4 && countdown > 0) {
      const timer =
        countdown > 0 && setInterval(() => setCountdown(countdown - 1), 1000);
      // @ts-ignore
      return () => clearInterval(timer);
    } else if (countdown < 1) {
      startRecording();
    }
  }, [countdown]);

  const handleReset = () => {
    props.onDelete();
  };

  const handleSuccess = (filepath: string) => {
    const fullpath = `https://${S3_BUCKET}/${filepath}`;
    props.onSuccess(fullpath);
  };

  const getFileName = (name: string): string => {
    const filesplit = name.split('.');
    const bname =
      btoa(name + Date.now().toString()) +
      '.' +
      filesplit[filesplit.length - 1];
    return bname;
  };

  const getFilePath = (name: string): string => {
    const filename = getFileName(name);
    const path = `uploads/${filename}`;
    return path;
  };

  const uploadToS3 = (file: any, filepath: string) => {
    const bucket = new AWS.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: S3_BUCKET },
      region: AWS_REGION,
      httpOptions: {
        timeout: 240000,
      },
    });
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: filepath,
    };
    bucket
      .putObject(params)
      .on('httpUploadProgress', (evt) => {
        let percentLoaded = Math.round((evt.loaded * 100) / evt.total);
        setProgress(percentLoaded);
        if (evt.loaded === evt.total) {
          handleSuccess(filepath);
        }
      })
      .send((err) => {
        if (err) {
          toast.error(String(err));
        }
      });
  };

  const handleSubmit = () => {
    const filename = getFilePath(
      `recording-${Math.floor(Math.random() * 1000000)}.mp4`
    );
    if (mediaBlob) {
      uploadToS3(mediaBlob.blob, filename);
    }
  };

  return (
    <>
      <Box>
        <Box>
          <Box sx={{ position: 'relative' }}>
            <>
              <Box
                sx={{
                  borderRadius: 15,
                  backgroundColor: 'black',
                  maxHeight: 40,
                  minHeight: 40,
                  minWidth: { xs: 225, sm: 200 },
                  maxWidth: { xs: 225, sm: 200 },
                  display: 'flex',
                  position: 'absolute',
                  right: 20,
                  top: 10,
                  zIndex: 101,
                }}
              >
                <Typography
                  sx={{
                    color: 'white',
                    pt: 1,
                    borderRadius: 15,
                    minWidth: 70,
                    maxWidth: 70,
                    backgroundColor:
                      recordingLength > 60 ? 'orange' : 'inherit',
                  }}
                >{`${String(Math.floor(recordingLength / 60)).padStart(
                  2,
                  '0'
                )}:${String(
                  recordingLength - Math.floor(recordingLength / 60) * 60
                ).padStart(2, '0')}`}</Typography>
                <CustomIcon
                  name={status === 'recording' ? 'pause' : 'recordcircle'}
                  hex="white"
                  disabled={status === 'stopped'}
                  onClick={() =>
                    status === 'recording'
                      ? pauseRecording()
                      : status === 'paused'
                      ? resumeRecording()
                      : setCountdown(countdown - 1)
                  }
                  sx={{
                    top: 8,
                    position: 'relative',
                    pl: 2,
                    cursor: 'pointer',
                  }}
                />
                <CustomIcon
                  name="stop"
                  hex="white"
                  disabled={!['recording', 'paused'].includes(status)}
                  sx={{
                    top: 8,
                    position: 'relative',
                    pl: 1,
                    cursor: 'pointer',
                    zIndex: 101,
                    fill: 'white',
                  }}
                  onClick={stopRecording}
                />
                {!['recording', 'paused'].includes(status) && (
                  <CustomIcon
                    name="trash"
                    hex="white"
                    sx={{
                      top: 8,
                      position: 'relative',
                      pl: { xs: 5, sm: 1 },
                      cursor: 'pointer',
                      zIndex: 101,
                      fill: 'white',
                    }}
                    onClick={handleReset}
                  />
                )}
              </Box>
            </>
          </Box>
          {status === 'stopped' && countdown === 0 && mediaBlob && (
            <div
              style={{
                width: 'auto',
                height: 'auto',
                position: 'relative',
              }}
            >
              <video
                src={mediaBlob.url}
                controls
                autoPlay
                loop
                width={'100%'}
                height={'100%'}
                preload="auto"
                webkit-playsinline="true"
                playsInline
              />
              <Button
                sx={{
                  backgroundColor: '#7E7CF2',
                  width: 150,
                  maxHeight: 100,
                  fontWeight: 'bold',
                  fontSize: 14,
                  margin: 'auto',
                  mt: 5,
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
              {progress && <ProgressBar progress={progress} />}
            </div>
          )}
          {!['stopped'].includes(status) && renderVideoPreview}
          {!['stopped'].includes(status) && (
            <Typography sx={{ pt: 2, pb: 5 }}>
              For best results, position yourself in the center of the frame
            </Typography>
          )}
          {platformOutofDate && (
            <Typography sx={{ pt: 2, pb: 5 }}>
              {`It looks like you may be using an older version of ${platform.platform}.  Older versions may not work as expected. If you experience any issues, please update to the latest version and try again`}
            </Typography>
          )}
          {status === 'idle' && devices.video.length > 0 && (
            <>
              <FormControl sx={{ mt: 1 }}>
                <InputLabel id="video-input-label">Select Video</InputLabel>
                <Select
                  labelId="video-input-label"
                  id="video-simple-select"
                  label="Select Video"
                  defaultValue={devices.video[0].deviceId}
                  onChange={(e) => setSelectedVideo(e.target.value)}
                >
                  {devices.video.map((device) => {
                    return (
                      <MenuItem key={device.deviceId} value={device.deviceId}>
                        {device.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ ml: { xs: 0, sm: 1 }, mt: 1 }}>
                <InputLabel id="audio-input-label">Select Audio</InputLabel>
                <Select
                  labelId="audio-input-label"
                  id="audio-simple-select"
                  label="Select Audio"
                  defaultValue={
                    (devices.audio &&
                      devices.audio.length > 0 &&
                      devices.audio[0].deviceId) ||
                    'default'
                  }
                  onChange={(e) => setSelectedAudio(e.target.value)}
                >
                  {devices.audio.map((device) => {
                    return (
                      <MenuItem key={device.deviceId} value={device.deviceId}>
                        {device.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </>
          )}
        </Box>
        <>
          {countdown > 0 && countdown < 4 && (
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                bgcolor: 'rgba(0, 0, 0, 0.7)',
                minWidth: '100%',
                maxWidth: '100%',
                minHeight: '100%',
                maxHeight: '100%',
                overflow: 'visible',
                outline: 'none',
                margin: 0,
                '&::-webkit-scrollbar': { width: 8 },
                '&::-webkit-scrollbar-track': {
                  background: '#F8F8F8',
                  borderRadius: 5,
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888',
                  borderRadius: 5,
                },
                '&::-webkit-scrollbar:horizontal': {
                  height: 0,
                  width: 0,
                  display: 'none',
                  zIndex: 1000,
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: 54,
                  margin: 'auto',
                  color: 'white',
                  top: '50%',
                  left: '50%',
                  position: 'absolute',
                }}
              >
                {countdown}
              </Typography>
            </Box>
          )}
        </>
      </Box>
    </>
  );
};
