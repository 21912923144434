import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { ColorSelect } from './ColorSelect';
import { FileUpload } from './FileUpload';
import { JamyrTextField, JamyrSwitch } from '../atoms';

export interface LabelValue {
  label: string;
  code: string;
  value?: any;
  type?: string;
  gridSize?: number;
  expandable?: boolean;
  helpText?: string;
  characterLimit?: number;
}

export interface SCardProps {
  title: string;
  handleUpdate?: (data: any) => void;
  data?: any;
  colors?: LabelValue[];
  uploads?: LabelValue[];
  inputs?: LabelValue[];
  content?: JSX.Element;
}

export const SettingsCard = (props: SCardProps): JSX.Element => {
  const getValue = (name: string) => {
    if (props.data && Object.keys(props.data).includes(name)) {
      return props.data[name];
    }
  };

  const handleChange = (field: string, value: string | boolean) => {
    props.data[field] = value;
    if (props.handleUpdate) {
      props.handleUpdate(props.data);
    }
  };

  return (
    <>
      <Typography
        component="h2"
        sx={{
          position: 'relative',
          fontSize: { xs: 16, sm: 24 },
          top: -10,
          textAlign: 'left',
          fontWeight: 700,
        }}
      >
        {props.title}
      </Typography>
      <Card
        variant="outlined"
        sx={{
          borderRadius: 3,
          padding: { xs: 1, sm: 5 },
          boxShadow: '0px 1px 16px rgba(137, 137, 137, 0.25)',
          display: 'grid',
        }}
      >
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              {props.content && props.content}
            </Grid>
          </Grid>
          <Grid container>
            {props.colors &&
              Object.values(props.colors).map(
                (item: LabelValue, idx: number) => {
                  return (
                    <Grid key={idx} item xs={6} md={2}>
                      <ColorSelect
                        title={item.label}
                        code={item.code}
                        color={getValue(item.code)}
                        onChange={handleChange}
                      />
                    </Grid>
                  );
                }
              )}
          </Grid>
          <Grid container>
            <Grid
              container
              item
              xs={props.inputs && props.inputs.length > 0 ? 12 : 0}
              md={props.inputs && props.inputs.length > 0 ? 8 : 0}
            >
              {props.inputs &&
                Object.values(props.inputs).map(
                  (item: LabelValue, idx: number) => {
                    return (
                      <Grid
                        key={idx}
                        item
                        sx={{ py: item.type === 'boolean' ? 4 : 2 }}
                        xs={item.gridSize ? item.gridSize : 12}
                      >
                        {item.type === 'boolean' && (
                          <JamyrSwitch
                            label={item.label}
                            defaultChecked={getValue(item.code) === true}
                            onChange={(e: any) =>
                              handleChange(item.code, e.target.checked)
                            }
                          />
                        )}
                        {item.type !== 'boolean' && (
                          <JamyrTextField
                            name={item.code}
                            label={item.label}
                            currentvalue={getValue(item.code)}
                            type={item.type}
                            sx={{ width: { xs: '100%', sm: '70%' } }}
                            changehandler={handleChange}
                            expandable={item.expandable}
                            helpText={item.helpText}
                            characterLimit={item.characterLimit}
                          />
                        )}
                      </Grid>
                    );
                  }
                )}
            </Grid>
            <Grid
              item
              xs={props.uploads && props.uploads.length > 0 ? 4 : 0}
              sx={{ paddingTop: 2, paddingBottom: 2 }}
            >
              {props.uploads &&
                Object.values(props.uploads).map(
                  (item: LabelValue, idx: number) => {
                    return (
                      <Grid key={idx} item>
                        <FileUpload
                          label={item.label}
                          value={getValue(item.code)}
                          showSuccess={false}
                          uploadDir={`uploads/${props.data.external_id}/branding/${item.code}`}
                          changehandler={(url: string) =>
                            handleChange(item.code, url)
                          }
                        />
                      </Grid>
                    );
                  }
                )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
