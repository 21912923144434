import { APIEndpoint, RequestInput } from "../../types/config";
import { BACKEND_URL } from "../../config/index";
import { CustomerSetting } from "../../types/user";
import { QueryClient } from "@tanstack/react-query";

const common_params = ["limit", "q", "sort", "sortDir", "page", "search", "expand"];

type Query = {
  key: string[];
  endpoint: APIEndpoint;
};

export type ListResource = {
  total: number;
  records: any;
};

export type PublicGet = {
  data: any;
  settings: CustomerSetting;
};

export enum HTTPMethods {
  DELETE = "DELETE",
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT"
}

export enum Api {
  CampaignGet = "CampaignGet",
  CampaignList = "CampaignList",
  CampaignCreate = "CampaignCreate",
  CampaignGetQuestions = "CampaignGetQuestions",
  CustomerGet = "CustomerGet",
  CustomerSetting = "CustomerSettingGet",
  JobDetailGet = "JobDetailGet",
  JobsList = "JobsList",
  QuestionCreate = "QuestionCreate",
  QuestionList = "QuestionList",
  QuestionLinkCreate = "QuestionLinkCreate",
  TestimonialCreate = "TestimonialCreate",
  TestimonialGet = "TestimonialGet",
  TestimonialList = "TestimonialList",
  TranscriptionGet = "TranscriptionGet",
  TransriptionUpdate = "TranscriptionUpdate",
  VersionsGet = "VersionsGet"
}

export const Queries: Record<Api, Query> = {
  CampaignGet: {
    key: ["CampaignGet"],
    endpoint: {
      path: "/campaigns/:company/:id",
      method: HTTPMethods.GET,
      pathParams: ["company", "id"]
    }
  },
  CampaignList: {
    key: ["CampaignList"],
    endpoint: {
      path: "/campaigns/:company",
      method: HTTPMethods.GET,
      pathParams: ["company"]
    }
  },
  CampaignCreate: {
    key: ["CampaignCreate"],
    endpoint: {
      path: "/campaigns/:company",
      method: HTTPMethods.POST,
      pathParams: ["company"]
    }
  },
  CampaignGetQuestions: {
    key: ["CampaignGetQuestions"],
    endpoint: {
      path: "/campaigns/:company/:id/generate_questions",
      method: HTTPMethods.GET,
      pathParams: ["company", "id"]
    }
  },
  CustomerGet: {
    key: ["CustomerGet"],
    endpoint: {
      path: "/customers/:id",
      method: HTTPMethods.GET,
      pathParams: ["customer"]
    }
  },
  CustomerSettingGet: {
    key: ["CustomerSettingGet"],
    endpoint: {
      path: "/customers/:id/branding",
      method: HTTPMethods.GET,
      pathParams: ["id"]
    }
  },
  TestimonialCreate: {
    key: ["TestimonialCreate"],
    endpoint: {
      path: "/testimonials/:id",
      method: HTTPMethods.POST,
      pathParams: ["id"]
    }
  },
  TestimonialGet: {
    key: ["TestimonialGet"],
    endpoint: {
      path: "/testimonials/:company/:id",
      method: HTTPMethods.GET,
      pathParams: ["company", "id"],
    }
  },
  TestimonialList: {
    key: ["TestimonialList"],
    endpoint: {
      path: "/testimonials/:company",
      method: HTTPMethods.GET,
      pathParams: ["company"],
      queryParams: ["campaign", "status"]
    }
  },
  JobDetailGet: {
    key: ["JobDetailGet"],
    endpoint: {
      path: "/jobs/detail",
      queryParams: ["link"],
      method: HTTPMethods.GET
    }
  },
  JobsList: {
    key: ["JobsList"],
    endpoint: {
      path: "/jobs",
      method: HTTPMethods.GET
    }
  },
  QuestionCreate: {
    key: ["QuestionCreate"],
    endpoint: {
      path: "/questions/:company",
      method: HTTPMethods.POST,
      pathParams: ["company"]
    }
  },
  QuestionList: {
    key: ["QuestionList"],
    endpoint: {
      path: "/questions/:company",
      method: HTTPMethods.GET,
      pathParams: ["company"],
      queryParams: ["campaign", "full"]
    }
  },
  QuestionLinkCreate: {
    key: ["QuestionLinkCreate"],
    endpoint: {
      path: "/questions/:company/:campaign",
      method: HTTPMethods.POST,
      pathParams: ["company", "campaign"]
    }
  },
  TranscriptionGet: {
    key: ["TranscriptionGet"],
    endpoint: {
      path: "/testimonials/:company/:id/transcription",
      method: HTTPMethods.GET,
      pathParams: ["company", "id"],
      queryParams: ["language"]
    }
  },
  TranscriptionUpdate: {
    key: ["TranscriptionUpdate"],
    endpoint: {
      path: "/testimonials/:company/:id/transcription/:language",
      method: HTTPMethods.PUT,
      pathParams: ["company", "id", "language"]
    }
  },
  VersionsGet: {
    key: ["VersionsGet"],
    endpoint: {
      path: "/testimonials/:company/:id/versions",
      method: HTTPMethods.GET,
      pathParams: ["company", "id"]
    }
  },
};

export const queryClient = new QueryClient();

export async function sendRequest<T>(
  endpoint: APIEndpoint,
  input?: RequestInput
): Promise<T> {
  const req = MakeRequest(endpoint, input || {});

  return fetch(req, {
    credentials: "include",
  })
    .then(async (res) => {
      if (!res.ok) {
        if (res.status === 401 && endpoint.path.endsWith("/me")) {
          localStorage.clear();
        }
        const err = await res.json();
        throw err;
      }
      return res;
    })
    .then((res) => {
      return res
        .json()
        .then((json) => json)
        .catch(() => {
          return {};
        });
    })
    .catch((error: Error) => {
      console.error("API Error:", error);
      throw error
    });
}

const MakeRequest = (
  endpoint: APIEndpoint,
  input: RequestInput
): RequestInfo => {
  let { method, path, pathParams, queryParams } = endpoint;
  if (queryParams) {
    queryParams.push(...common_params);
  } else {
    queryParams = common_params;
  }
  let fixedPath = BACKEND_URL + path;
  if (pathParams?.length && input.pathParams) {
    for (const p of pathParams) {
      fixedPath = fixedPath.replace(`/:${p}`, `/${input.pathParams[p]}`);
    }
  }

  if (input.queryParams) {
    fixedPath += "?" + new URLSearchParams(input.queryParams);
  }

  let jsonBody = "";
  if (input.body) {
    jsonBody = JSON.stringify(input.body);
  }

  const ctrl = new AbortController();
  const sig = ctrl.signal;

  setTimeout(() => ctrl.abort(), 20000);
  let headers: { [name: string]: string } = {
    "Content-Type": "application/json",
  }
  return new Request(fixedPath, {
    body: jsonBody || null,
    method: method,
    signal: sig,
    headers: headers,
    credentials: 'include',
  });
};
