import React from 'react';
import { SvgIconProps } from '@mui/material';
import {
  ImageOutlined,
  OndemandVideoOutlined,
  GifOutlined,
} from '@mui/icons-material';
import {
  AddIcon,
  CameraIcon,
  ChartIcon,
  CloseIcon,
  CodeIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  FolderIcon,
  GearIcon,
  GreenHouse,
  HeartIcon,
  InfoIcon,
  JamyrLogo,
  LinkIcon,
  MailIcon,
  NextIcon,
  PauseIcon,
  PersonIcon,
  PlayIcon,
  PlayerIcon,
  PlaySimple,
  PreviewIcon,
  QuestionIcon,
  RecordIcon,
  SearchIcon,
  ShieldIcon,
  StopIcon,
  TilesIcon,
  TrashIcon,
  UploadIcon,
  StudioIcon,
  CodeblockIcon,
  LogoutIcon,


} from './icons/StandardIcons';

import { SettingIntrIcon } from './icons/InterfaceIcons';

import { LocationDrwIcon } from './icons/drawn/DrawnIcons';

export type IconProps = SvgIconProps & {
  name: string;
  disabled?: boolean;
  hex?: string;
};

export const CustomIcon = (props: IconProps): JSX.Element => {
  const iconMapping: { [name: string]: JSX.Element } = {
    add: <AddIcon {...props} />,
    chart: <ChartIcon {...props} />,
    close: <CloseIcon {...props} />,
    code: <CodeIcon {...props} />,
    delete: <DeleteIcon {...props} />,
    download: <DownloadIcon {...props} />,
    edit: <EditIcon {...props} />,
    folder: <FolderIcon {...props} />,
    gear: <GearIcon {...props} />,
    gif: <GifOutlined {...props} />,
    greenhouse: <GreenHouse {...props} />,
    heart: <HeartIcon {...props} />,
    info: <InfoIcon {...props} />,
    image: <ImageOutlined {...props} />,
    jamyr: <JamyrLogo {...props} />,
    link: <LinkIcon {...props} />,
    location: <LocationDrwIcon {...props} />,
    mail: <MailIcon {...props} />,
    next: <NextIcon {...props} />,
    pause: <PauseIcon {...props} />,
    person: <PersonIcon {...props} />,
    play: <PlayIcon {...props} />,
    player: <PlayerIcon {...props} />,
    playsimp: <PlaySimple {...props} />,
    preview: <PreviewIcon {...props} />,
    question: <QuestionIcon {...props} />,
    record: <CameraIcon {...props} />,
    recordcircle: <RecordIcon {...props} />,
    search: <SearchIcon {...props} />,
    setting: <SettingIntrIcon {...props} />,
    shield: <ShieldIcon {...props} />,
    stop: <StopIcon {...props} />,
    studio: <StudioIcon {...props} />,
    tiles: <TilesIcon {...props} />,
    trash: <TrashIcon {...props} />,
    upload: <UploadIcon {...props} />,
    video: <OndemandVideoOutlined {...props} />,
    codeblock: <CodeblockIcon {...props} />,
    logout: <LogoutIcon {...props} />,

  };
  return iconMapping[props.name] || <></>;
};
