import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import useAPI from '../../utils/useApi';
import { APIRequests } from '../../config/api';
import {
  branding,
  brandingNavItems,
} from '../../config/layouts/customerSettings';
import { RequestInput } from '../../types/config';
import { CustomerSetting } from '../../types/user';
import { SelectOption, WidgetLocation } from '../../atoms';
import {
  useGlobalState,
  useCurrentCompany,
} from '../../utils/GlobalStateProvider';
import {
  SettingsCard,
  SCardProps,
  PageNavigation,
  PageNavItem,
  ATSSettings,
} from '../../components';
import { CustomIcon, JamyrMultiSelect, Loading } from '../../atoms';
import { toast } from 'react-toastify';
import { transcriptionLanguages } from '../../config';

export const Settings = (): JSX.Element => {
  const customer = useAPI<CustomerSetting>(APIRequests.CustomerBrandingGet);
  const customerUpdate = useAPI(APIRequests.CustomerBrandingUpdate);
  const [settings, setSettings] = useState<CustomerSetting>();
  const context = useGlobalState(true);
  const company = useCurrentCompany();

  useEffect(() => {
    if (context.state.company) {
      const req: RequestInput = {
        pathParams: { customer: company || context.state.company },
      };
      customer.execute(req);
    }
  }, [context.state.company]);

  useEffect(() => {
    if (customer.data) {
      setSettings(customer.data as CustomerSetting);
    }
  }, [customer.data]);

  useEffect(() => {
    if (customerUpdate.data) {
      toast('🦄 Settings Updated!');
    } else if (customerUpdate.error) {
      toast.error(String(customerUpdate.error));
    }
  }, [customerUpdate.data, customerUpdate.error]);

  const handleSettingsUpdate = (data: any) => {
    const req: RequestInput = {
      pathParams: { customer: data.customer_id },
      body: data,
    };
    customerUpdate.execute(req);
  };

  const copyWidgetCode = () => {
    const codelink = `<script async type="text/javascript" src="https://app.jamyr.com/js/pub/application.js" id="jamyr-app" data-company="${settings?.customer?.slug}"></script>`;
    navigator.clipboard.writeText(codelink);
    toast('🦄 Link copied!');
  };

  const getProps = (brandItem: SCardProps) => {
    brandItem.data = settings;
    brandItem.handleUpdate = handleSettingsUpdate;
    if (brandItem.title === 'ATS Integration') {
      brandItem.content = (
        <ATSSettings onUpdate={handleSettingsUpdate} data={settings} />
      );
    }
    if (brandItem.title === 'Video Widget') {
      const handleLocationChange = (data: string) => {
        handleSettingsUpdate({ ...settings, widget_position: data });
      };
      brandItem.content = (
        <Box>
          <Typography sx={{ fontWeight: 'bold', mb: 2 }}>
            Widget Code
            <CustomIcon
              name="link"
              sx={{ position: 'relative', top: 6, left: 5, cursor: 'pointer' }}
              onClick={copyWidgetCode}
            />
          </Typography>
          <WidgetLocation
            onSave={handleLocationChange}
            current={settings?.widget_position}
          />
          <Typography sx={{ fontWeight: 'bold', mt: 4 }}>
            Widget Branding
          </Typography>
        </Box>
      );
    }
    if (brandItem.title === 'Transcriptions') {
      const handleChange = (values: SelectOption[]) => {
        const value_ids = values.map((value) => {
          return value.id;
        });
        if (settings) {
          settings.languages = value_ids.join(';');
          handleSettingsUpdate(settings);
        }
      };
      const initialSelect: SelectOption[] = transcriptionLanguages.filter(
        (tl) =>
          settings?.languages && settings.languages.split(';').includes(tl.id)
      );
      brandItem.content = (
        <JamyrMultiSelect
          name="languages"
          placeholder="Transcription Languages"
          options={transcriptionLanguages}
          handlechange={handleChange}
          selected={initialSelect}
        />
      );
    }
    return brandItem;
  };

  return (
    <>
      {settings && (
        <Grid container sx={{ maxWidth: { xs: '80%', sm: '100%' } }}>
          <Grid
            item
            xs={12}
            sx={{ ml: { xs: 2, sm: 10 }, mt: { xs: 2, sm: 10 } }}
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: 24, sm: 36 },
                fontWeight: 'bold',
              }}
            >
              Settings
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ ml: { xs: 1, sm: 5 } }}>
            <PageNavigation {...brandingNavItems} />
          </Grid>
          {brandingNavItems.map((item: PageNavItem) => {
            return (
              <Grid
                xs={12}
                item
                key={item.link}
                id={item.link}
                sx={{
                  ml: { xs: 2, sm: 10 },
                  mt: { xs: 2, sm: 5 },
                  mr: { xs: 0, sm: 10 },
                }}
              >
                <SettingsCard {...getProps(branding[item.title])} />
              </Grid>
            );
          })}
        </Grid>
      )}
      {!settings && <Loading fullpage={true} />}
    </>
  );
};
