import React, { useEffect, useState } from 'react';
import { Grid, TextField, Button, Typography } from '@mui/material';
import { Alert } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useGlobalState } from '../utils/GlobalStateProvider';
import { login, isAuthenticated, oktaLogin } from '../utils/auth';
import { APIRequests } from '../config/api';
import { User } from '../types/user';
import useAPI from '../utils/useApi';
import { useOktaAuth } from '@okta/okta-react';
import { CustomUserClaims, UserClaims } from '@okta/okta-auth-js';

export const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const { setState } = useGlobalState();
  const user = useAPI<User>(APIRequests.Me);

  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaims<CustomUserClaims> | null>(
    null
  );

  const oktaLoginButton = async () => {
    await oktaAuth.signInWithRedirect();
  };

  let next = email.endsWith('@jamyr.com')
    ? '/admin/customers'
    : '/dashboard/questions';

  useEffect(() => {
    if (success) {
      user.execute({});
    }
  }, [success]);

  useEffect(() => {
    if (user.data) {
      setState((prevState) => ({
        ...prevState,
        ...{ user: user.data, company: user.data.customer_id },
      }));
      navigate(next);
    }
  }, [user.data]);

  const handleSubmit = async (_: React.MouseEvent) => {
    setError('');
    try {
      await login(email, password);
      setSuccess(true);
    } catch (err) {
      if (err instanceof Error) {
        // handle errors thrown from frontend
        setError(err.message);
      } else {
        // handle errors thrown from backend
        setError('There was an issue logging you in');
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (!authState?.isAuthenticated) {
      setUserInfo(null);
    } else {
      oktaAuth
        .getUser()
        .then((info: UserClaims<CustomUserClaims>) => {
          setUserInfo(info);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    if (userInfo && !success) {
      oktaLogin(userInfo, next);
      navigate(next);
    }
  }, [userInfo]);

  const oktaSignIn = (
    <Button
      variant="text"
      onClick={oktaLoginButton}
      sx={{
        backgroundColor: 'transparent',
        color: 'info.main',
        '&:hover': {
          backgroundColor: 'transparent',
          color: 'info.main',
        },
      }}
    >
      Sign in with Okta
    </Button>
  );

  return isAuthenticated() ? (
    <Navigate to={next} />
  ) : authState?.isAuthenticated ? (
    <Typography>Please Wait</Typography>
  ) : (
    <>
      <Grid container justifyContent="center">
        <Grid
          item
          xs={4}
          direction="column"
          container
          justifyContent="center"
          sx={{
            backgroundImage:
              'linear-gradient(146deg, rgba(249, 225, 255, 0.7) 17%, rgba(225, 244, 255, 0.8))',
            minHeight: '100vh',
            textAlign: 'center',
            display: 'flex',
          }}
        >
          <img
            src="/images/logo-block-purple.png"
            alt="Jamyr Logo"
            style={{
              width: '50%',
              margin: 'auto',
              display: 'block',
            }}
          />
        </Grid>
        <Grid
          item
          xs={8}
          container
          justifyContent="center"
          rowSpacing={1}
          sx={{
            padding: { xs: 1, sm: 10, md: 20, lg: 30 },
          }}
        >
          <Grid item xs={12} container justifyContent="center">
            <Typography variant="h4" color="primary">
              Login to your account
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email"
              label="Email"
              type="email"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.currentTarget.value.trim())
              }
              autoFocus
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.currentTarget.value.trim())
              }
              required
              fullWidth
            />
          </Grid>
          <Grid container alignItems="center">
            {error && (
              <Grid item>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{ width: '100%' }}
            >
              Login
            </Button>
          </Grid>
          {oktaSignIn}
          <Grid></Grid>
          <Grid item xs={12}>
            <Button
              variant="text"
              href="/reset-password"
              sx={{
                backgroundColor: 'transparent',
                color: 'info.main',
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: 'info.main',
                },
              }}
            >
              Forgot password?
            </Button>
          </Grid>
          <Typography
            sx={{
              color: 'text.disabled',
              fontSize: 14,
              position: 'absolute',
              bottom: 100,
            }}
          >
            By using Jamyr you accept and agree to our{' '}
            <a
              href="https://www.recruitics.com/terms-and-conditions"
              style={{ textDecoration: 'none' }}
            >
              Terms & Conditions
            </a>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
