import {
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import {
  Customer,
  CustomerSetting,
} from "../../types";
import { Queries, sendRequest } from "./api";

export const useCustomerGet =   (
  pParams: { [name: string]: string }
) => {
  return useQuery({
    queryKey: Queries.CustomerGet.key,
    queryFn: () =>
      sendRequest<Customer>(Queries.CustomerSettingGet.endpoint, { pathParams: pParams }),
  })
};

export const useCustomerSettingGet =   (
  pParams: { [name: string]: any }
) => {
  return useQuery({
    queryKey: Queries.CustomerSettingGet.key,
    queryFn: () =>
      sendRequest<CustomerSetting>(Queries.CustomerSettingGet.endpoint, { pathParams: pParams }),
    enabled: !!pParams.id
  })
};