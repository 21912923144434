import { getCookie, setCookie, eraseCookie } from "./global";
import { JamyrCookies } from "../types/config";
import { useOktaAuth } from "@okta/okta-react"
import { CustomUserClaims, UserClaims } from "@okta/okta-auth-js";

export const isAuthenticated = (): boolean => {
  return getCookie(JamyrCookies.SITE) !== undefined;
};

const IsOktaAuthenticated = (): boolean => {
  const { authState } = useOktaAuth();
  let isAuthorized = authState?.isAuthenticated;

  return isAuthorized === true;
}

/**
 * Login to backend and store JSON web token on success
 *
 * @param email
 * @param password
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const login = async (email: string, password: string) => {
  // Assert email or password is not empty
  if (!(email.length > 0) || !(password.length > 0)) {
    throw new Error('Email or password was not provided');
  }
  const formData = new FormData();
  // OAuth2 expects form data, not JSON data
  formData.append('username', email);
  formData.append('password', password);

  const request = new Request(`${process.env.REACT_APP_API_URL}/token`, {
    method: 'POST',
    body: formData,
  });

  const response = await fetch(request, {
    credentials: "include",
  });

  if (response.status === 500) {
    throw new Error('Internal server error');
  }

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw new Error(data.detail);
    }
    throw new Error(data);
  }
  if ("access_token" in data) {
    setCookie(JamyrCookies.SITE, data["access_token"], 1);
  }
  return data;
};

export const oktaLogin = async (user: UserClaims<CustomUserClaims> | null, next: string) => {
  // Assert email or password is not empty

  const userData = {
    email: user?.email,
    name: user?.name,
  }

  const request = new Request(`${process.env.REACT_APP_API_URL}/okta/validate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  });

  const response = await fetch(request, {
    credentials: "include",
  });
  console.log(response);

  if (response.status === 500) {
    throw new Error('Internal server error');
  }

  const responseData = await response.json();
  console.log(responseData);

  if (response.status > 400 && response.status < 500) {
    if (responseData.detail) {
      throw new Error(responseData.detail);
    }
    throw new Error(responseData);
  }
  if ("access_token" in responseData) {
    setCookie(JamyrCookies.SITE, responseData["access_token"], 1);
  }
  return responseData;

};


export const useOktaLogout = () => {
  const { oktaAuth } = useOktaAuth();

  const OktaLogout = async () => {
    await oktaAuth.signOut();
  };

  return OktaLogout;
};


export const useLogout = () => {
  const oktaCheck = IsOktaAuthenticated();
  const OktaLogout = useOktaLogout();

  const logout = async () => {
    if (oktaCheck) {
      console.log("Logout Triggered");
      localStorage.removeItem('token');
      eraseCookie(JamyrCookies.SITE);
      await OktaLogout();
    } else {
      localStorage.removeItem('token');
      eraseCookie(JamyrCookies.SITE);
      window.location.href = "/login";
    }
  };

  return logout;
};
