import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { KeyboardBackspace, PhotoLibrary } from '@mui/icons-material';
import useAPI from '../../utils/useApi';
import { useParams } from 'react-router-dom';
import { APIRequests, ListResource } from '../../config/api';
import { useCustomerSettingGet } from '../../config/api/customer';
import {
  videoCardLayout,
  videoNavItems,
} from '../../config/layouts/videoSettings';
import { RequestInput } from '../../types/config';
import { Testimonial } from '../../types/testimonial';
import { Collection, CollectionLink } from '../../types/collection';
import {
  useGlobalState,
  useCurrentCompany,
} from '../../utils/GlobalStateProvider';
import {
  PageFormatSelect,
  PageNavigation,
  PageNavItem,
  SettingsCard,
  SCardProps,
  SummaryTile,
  SumTileProps,
  TestimonialViews,
  TranscriptionUpdate,
  UploadCard,
  VideoHistory,
  VideoEditor,
  SharingAssets,
  VideoCardList,
} from '../../components';
import {
  CustomIcon,
  Loading,
  JamyrTextField,
  JamyrMultiSelect,
  SelectOption,
  JamyrCard,
  PopupModal,
} from '../../atoms';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';

import { useModal } from 'mui-modal-provider';
import { transcriptionLanguages } from '../../config';
import { isMobile } from '../../utils/global';

export const VideoDetail = (): JSX.Element => {
  const video = useAPI<Testimonial>(APIRequests.TestimonialGet);
  const testimonialUpdate = useAPI(APIRequests.TestimonialUpdate);
  const collection = useAPI<ListResource>(APIRequests.CollectionsList);
  const collectionLinksList = useAPI<ListResource>(APIRequests.CollectionsList);
  const colLinkCreate = useAPI(APIRequests.CollectionLinkCreate);
  const colLinkDelete = useAPI(APIRequests.CollectionLinkDelete);
  const [testimonial, setTestimonial] = useState<Testimonial>();
  const settings = useCustomerSettingGet({
    id: video.data?.customer_id,
  });
  const [collections, setCollections] = useState<SelectOption[]>([]);
  const [collectionLinks, setCollectionLinks] = useState<CollectionLink[]>([]);
  const [selectedCollections, setSelectedCollections] = useState<
    SelectOption[]
  >([]);
  const [fetchData, setFetchData] = useState<boolean>(false);
  const [showCollections, setShowCollections] = useState<boolean>(false);
  const [editDetails, setEditDetails] = useState<boolean>(false);
  const context = useGlobalState(true);
  const company = useCurrentCompany();
  const { videoId } = useParams();
  const navigate = useNavigate();
  const { showModal } = useModal();

  useEffect(() => {
    if (context.state.company && videoId) {
      const req: RequestInput = {
        pathParams: {
          company: company || context.state.company,
          id: videoId,
        },
      };
      video.execute(req);
      const collectreq: RequestInput = {
        pathParams: {
          company: company || context.state.company,
        },
      };
      collection.execute(collectreq);
      setFetchData(false);
    }
  }, [context.state.company, fetchData]);

  useEffect(() => {
    if (video.data) {
      const t = video.data;
      setTestimonial(t);
      const req: RequestInput = {
        pathParams: {
          company: company || context.state.company,
        },
        queryParams: { testimonial: t.id },
      };
      collectionLinksList.execute(req);
    }
  }, [video.data]);

  useEffect(() => {
    if (collection.data) {
      const tCollection = collection.data.records as Collection[];
      let collectiondata: SelectOption[] = [];
      tCollection.forEach((item: Collection) => {
        collectiondata.push({ id: String(item.id), value: item.title });
      });
      setCollections(collectiondata);
    }
  }, [collection.data]);

  useEffect(() => {
    if (collectionLinksList.data && collectionLinksList.data.records) {
      let collectiondata: SelectOption[] = [];
      collectionLinksList.data.records.forEach((item: CollectionLink) => {
        const collectionRecord = collections.filter(
          (collection) => collection.id === String(item.collection_id)
        )[0];
        if (collectionRecord) {
          collectiondata.push(collectionRecord);
        }
      });
      setCollectionLinks(collectionLinksList.data.records);
      setSelectedCollections(collectiondata);
      setShowCollections(true);
    }
  }, [collectionLinksList.data]);

  useEffect(() => {
    if (testimonialUpdate.data) {
      toast('🦄 Video Updated!');
      setFetchData(true);
    }
    if (testimonialUpdate.error) {
      toast.error(String(testimonialUpdate.error));
    }
  }, [testimonialUpdate.data, testimonialUpdate.error]);

  useEffect(() => {
    if (colLinkCreate.data) {
      toast('🦄 Collection Link Created!');
      setFetchData(true);
    }
    if (colLinkCreate.error) {
      toast.error(String(colLinkCreate.error));
    }
  }, [colLinkCreate.data, colLinkCreate.error]);

  useEffect(() => {
    if (colLinkDelete.data) {
      toast('🦄 Collection Link Deleted');
      setFetchData(true);
    }
    if (colLinkDelete.error) {
      toast.error(String(colLinkDelete.error));
    }
  }, [colLinkDelete.data, colLinkDelete.error]);

  const updateTestimonialField = (field: string, value: string) => {
    let data = testimonial as any;
    data[field] = value;
    updateTestimonial(data);
  };

  const updateTestimonial = (data: any) => {
    const req: RequestInput = {
      pathParams: {
        company: company || context.state.company,
        id: data.id,
      },
      body: data,
    };
    testimonialUpdate.execute(req);
  };

  const getTagsAsOptions = (values: string) => {
    if (values) {
      let returnlist: SelectOption[] = [];
      const allValues = values.split(';');
      allValues.forEach((val) => {
        returnlist.push({ id: val, value: val });
      });
      return returnlist;
    } else {
      return undefined;
    }
  };

  const handleLinkCollection = (option: SelectOption) => {
    const req: RequestInput = {
      pathParams: {
        company: company || context.state.company,
        collection_id: option.id,
      },
      body: {
        testimonial_id: testimonial?.id,
        collection_id: option.id,
      },
    };
    colLinkCreate.execute(req);
  };

  const showVideoEditor = () => {
    const modal = showModal(PopupModal, {
      children: (
        <VideoEditor
          testimonial={testimonial!}
          onComplete={() => {
            modal.destroy();
            setFetchData(true);
          }}
        />
      ),
    });
  };

  const showAssetEditor = () => {
    showModal(PopupModal, {
      children: (
        <Box
          sx={{
            width: '100%',
            mx: 20,
          }}
        >
          <Typography sx={{ textAlign: 'center', fontSize: 36, pb: 10 }}>
            Manage Assets
          </Typography>
          <Box
            sx={{ display: { xs: 'inherit', sm: 'flex' } }}
            justifyContent={'center'}
            alignContent={'center'}
          >
            <UploadCard
              label="GIF"
              uploadPath={`testimonials/${testimonial?.external_id}/`}
              onUpdate={(url: string) => updateTestimonialField('preview', url)}
              current={testimonial?.preview || ''}
            />
            <UploadCard
              label="Image"
              uploadPath={`testimonials/${testimonial?.external_id}/`}
              onUpdate={(url: string) =>
                updateTestimonialField('thumbnail', url)
              }
              current={testimonial?.thumbnail || ''}
            />
          </Box>
        </Box>
      ),
    });
  };

  const handleRemoveCollectionLink = (option: SelectOption) => {
    const link = collectionLinks.filter(
      (link) => link.collection_id === parseInt(option.id)
    )[0];
    if (link) {
      const req: RequestInput = {
        pathParams: {
          company: company || context.state.company,
          collectionid: link.collection_id,
          id: link.id,
        },
      };
      colLinkDelete.execute(req);
    }
  };

  const getTracks = () => {
    if (testimonial && settings.data) {
      let tracks = [
        {
          kind: 'captions',
          label: 'English',
          src: `https://s3.amazonaws.com/widget.jamyr.com/transcriptions/${testimonial.external_id}.vtt`,
          srcLang: 'en',
          default: true,
        },
      ];
      if (settings.data.languages) {
        const langs = settings.data.languages.split(';');
        langs.forEach((l) => {
          tracks.push({
            kind: 'captions',
            label: transcriptionLanguages.filter((tl) => tl.id === l)[0].value,
            src: `https://s3.amazonaws.com/widget.jamyr.com/transcriptions/${
              testimonial.external_id
            }/${l.split('-')[0]}/${testimonial.external_id}.vtt`,
            srcLang: l.split('-')[0],
            default: false,
          });
        });
      }
      return tracks;
    } else {
      return [];
    }
  };

  const renderCard = (item: SCardProps) => {
    item.data = testimonial;
    if (testimonial) {
      if (item.title === 'Preview') {
        const data: SumTileProps = {
          title: '',
          detail: [],
          columns: [
            {
              title: '',
              detail: (
                <>
                  <ReactPlayer
                    url={testimonial.final_video || testimonial.video}
                    controls={true}
                    maxWidth={
                      testimonial.page_style !== 'jamland' ? 200 : '100%'
                    }
                    width={'100%'}
                    maxHeight={'60vh'}
                    style={{
                      borderRadius: 5,
                      backgroundImage: 'url(/images/human.png)',
                      backgroundSize: 'cover',
                    }}
                    config={{
                      file: {
                        attributes: {
                          crossOrigin: 'true',
                        },
                        tracks: getTracks(),
                      },
                    }}
                  />
                  <Box display="flex" sx={{ mt: 1, position: 'relative' }}>
                    {testimonial.video_edit_status && (
                      <Typography>
                        Video Editing In Progress
                        <CircularProgress />
                      </Typography>
                    )}
                    {!testimonial.video_edit_status && (
                      <Button onClick={() => showVideoEditor()}>
                        Edit Video
                      </Button>
                    )}
                    {!testimonial.video_edit_status &&
                      testimonial.last_video_version && (
                        <Button
                          sx={{ ml: 1 }}
                          onClick={() => {
                            var t = testimonial;
                            t.final_video = testimonial.last_video_version!;
                            t.last_video_version = ' ';
                            updateTestimonial(t);
                          }}
                        >
                          Revert
                        </Button>
                      )}
                    <Box sx={{ position: 'absolute', right: 0 }}>
                      <Tooltip title="Manage Assets">
                        <IconButton>
                          <PhotoLibrary onClick={() => showAssetEditor()} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </>
              ),
            },
          ],
        };
        const cardProps = {
          title: item.title,
          children: <SummaryTile {...data} />,
        };
        return <JamyrCard {...cardProps} />;
      } else if (item.title === 'Sharing') {
        const cardProps = {
          title: item.title,
          children: <SharingAssets testimonial={testimonial} />,
          height: '82%',
        };
        return <JamyrCard {...cardProps} />;
      } else if (item.title === 'URLs') {
        const handleChange = (values: SelectOption[]) => {
          let vals: string[] = [];
          values.forEach((option) => {
            if (option.id) {
              vals.push(option.id);
            } else {
              vals.push(option as any as string);
            }
          });
          const val: string = vals.join(';');
          updateTestimonialField('url_tags', val);
        };
        const cardProps = {
          title: item.title,
          children: (
            <JamyrMultiSelect
              name="url-tags"
              selected={getTagsAsOptions(testimonial.url_tags)}
              placeholder="Do not include https://"
              allowinput={true}
              handlechange={handleChange}
            />
          ),
        };
        return <JamyrCard {...cardProps} />;
      } else if (item.title === 'Category Tags') {
        const handleChange = (values: SelectOption[]) => {
          let vals: string[] = [];
          values.forEach((option) => {
            if (option.id) {
              vals.push(option.id);
            } else {
              vals.push(option as any as string);
            }
          });
          const val: string = vals.join(';');
          updateTestimonialField('category_tags', val);
        };
        const cardProps = {
          title: item.title,
          children: (
            <JamyrMultiSelect
              name="category-tags"
              placeholder="e.g. Culture"
              allowinput={true}
              handlechange={handleChange}
              selected={getTagsAsOptions(testimonial.category_tags)}
            />
          ),
        };
        return <JamyrCard {...cardProps} />;
      } else if (item.title === 'Page Format') {
        const onChange = (field: string, value: string) => {
          updateTestimonialField(field, value);
        };

        return (
          <PageFormatSelect
            testimonial={testimonial}
            changehandler={onChange}
          />
        );
      } else if (item.title === 'Collections') {
        const handleChange = (values: SelectOption[]) => {
          const selection_ids = selectedCollections.map((collection) => {
            return collection.id;
          });
          const value_ids = values.map((value) => {
            return value.id;
          });
          const removed = selectedCollections.filter(
            (collection) => !value_ids.includes(collection.id)
          );
          const added = values.filter(
            (value) => !selection_ids.includes(value.id)
          );
          if (added.length > 0) {
            handleLinkCollection(added[0]);
          }
          if (removed.length > 0) {
            handleRemoveCollectionLink(removed[0]);
          }
        };
        const cardProps = {
          title: item.title,
          children: (
            <JamyrMultiSelect
              name="collections-list"
              placeholder="Collections List"
              options={collections}
              handlechange={handleChange}
              selected={selectedCollections}
            />
          ),
        };
        return showCollections ? <JamyrCard {...cardProps} /> : <></>;
      } else if (item.title === 'Feedback') {
        const cardProps = {
          title: 'Editing Notes',
          children: <VideoHistory testimonial_id={testimonial.id} />,
        };
        return <JamyrCard {...cardProps} />;
      } else if (item.title === 'Transcription') {
        const cardProps = {
          title: item.title,
          children: <TranscriptionUpdate testimonial={testimonial} />,
        };
        return <JamyrCard {...cardProps} />;
      } else if (item.title === 'Analytics') {
        return (
          <JamyrCard
            title="Analytics"
            height="400px"
            children={
              <TestimonialViews recordid={`${testimonial.external_id}`} />
            }
          />
        );
      } else if (item.title === 'Versions') {
        return (
          <JamyrCard
            title="Versions"
            height="400px"
            children={
              <VideoCardList
                parent={testimonial.external_id}
                onClick={(t) => navigate(`/dashboard/videos/${t.external_id}`)}
              />
            }
          />
        );
      } else {
        item.handleUpdate = updateTestimonial;
        return <SettingsCard {...item} />;
      }
    } else {
      return <></>;
    }
  };

  return (
    <>
      {testimonial && (
        <Grid
          container
          sx={{ ml: { sx: 0, sm: 5 }, maxWidth: { xs: '85%', sm: '100%' } }}
        >
          <Grid
            item
            xs={6}
            sx={{
              pt: { xs: 8, sm: 10 },
              pl: { xs: 3, sm: 7 },
              position: 'relative',
            }}
          >
            <KeyboardBackspace
              sx={{ position: 'absolute', cursor: 'pointer' }}
              onClick={() => navigate(-1)}
            />
            <Typography
              component="span"
              onClick={() => navigate(-1)}
              sx={{ cursor: 'pointer', pl: 3 }}
            >
              Back to videos
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PageNavigation {...videoNavItems} />
          </Grid>
          <Grid item xs={12}>
            {!editDetails && (
              <Grid item sx={{ pl: { xs: 2, sm: 5 }, pt: 5 }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                  {testimonial.question_text}
                  <CustomIcon
                    name="edit"
                    sx={{ ml: 1, cursor: 'pointer' }}
                    onClick={() => setEditDetails(true)}
                  />
                </Typography>
                <Typography>{`${testimonial.first_name} ${testimonial.last_name}`}</Typography>
              </Grid>
            )}
            {editDetails && (
              <JamyrCard title="Edit Details">
                <Grid container>
                  <Grid item xs={12} container justifyContent="flex-end">
                    <CustomIcon name="close" sx={{cursor: 'pointer'}} onClick={() => setEditDetails(false)}/>
                  </Grid>
                  <Grid item xs={12}>
                    <JamyrTextField
                      name="question_text"
                      label="Question Text"
                      currentvalue={testimonial.question_text}
                      changehandler={updateTestimonialField}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ pr: { xs: 0, sm: 1 } }}>
                    <JamyrTextField
                      name="first_name"
                      label="First Name"
                      currentvalue={testimonial.first_name}
                      fullWidth
                      changehandler={updateTestimonialField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ pl: { xs: 0, sm: 1 } }}>
                    <JamyrTextField
                      name="last_name"
                      label="Last Name"
                      currentvalue={testimonial.last_name}
                      fullWidth
                      changehandler={updateTestimonialField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <JamyrTextField
                      name="title"
                      label="Title"
                      currentvalue={testimonial.title}
                      fullWidth
                      changehandler={updateTestimonialField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <JamyrTextField
                      name="label"
                      label="Label"
                      currentvalue={testimonial.label}
                      helpText="Use this to categorize your videos internally.  Especially useful when versioning videos."
                      fullWidth
                      changehandler={updateTestimonialField}
                    />
                  </Grid>
                </Grid>
              </JamyrCard>
            )}
          </Grid>
          {videoNavItems.map((item: PageNavItem) => {
            return (
              <Grid
                item
                key={item.link}
                id={item.link}
                xs={12}
                sm={item.size ? item.size : 12}
                sx={{
                  mx: ['settings'].includes(item.link) ? { xs: 2, sm: 5 } : 0,
                }}
              >
                {renderCard(videoCardLayout[item.title])}
              </Grid>
            );
          })}
        </Grid>
      )}
      {!testimonial && <Loading fullpage={true} />}
    </>
  );
};
