import React, { useState, useEffect } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { CustomIcon } from '../../atoms';
import { FileUpload } from '../FileUpload';
import { TestimonialIn, QuestionLink } from '../../types/testimonial';
import { Employee } from '../../types/user';
import { JamyrCookies } from '../../types/config';
import { getCookie } from '../../utils/global';
import { useTestimonialCreate } from '../../config/api/testimonials';
import { WebRecorder } from '../../atoms/WebRecorder';

export interface QuestionUploadProps {
  questions: QuestionLink[];
  customer_id: number;
  oncomplete: () => void;
}

export const QuestionUpload = (props: QuestionUploadProps) => {
  const [curQ, setCurQ] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [employee, setEmployee] = useState<Employee>();
  const testimonialCreate = useTestimonialCreate({
    id: String(props.customer_id),
  });
  useEffect(() => {
    const data = getCookie(JamyrCookies.EMPLOYEE);
    if (!employee && data) {
      const userinfo = data.split('*&');
      let emp: Employee = {};
      if (userinfo.length === 5) {
        // Pronouns are not required, so handling for that
        emp = {
          first_name: userinfo[0],
          last_name: userinfo[1],
          email: userinfo[2],
          title: userinfo[3],
        };
      } else if (userinfo.length === 6) {
        emp = {
          first_name: userinfo[0],
          last_name: userinfo[1],
          pronouns: userinfo[2],
          email: userinfo[3],
          title: userinfo[4],
        };
      }

      setEmployee(emp);
    }
  }, []);

  useEffect(() => {
    if (testimonialCreate.data) {
      handleNext();
    }
  }, [testimonialCreate.data, testimonialCreate.error]);

  const createTestimonial = (url: string) => {
    const t: TestimonialIn = {
      customer_id: props.customer_id,
      campaign_id: props.questions[curQ].campaign_id,
      video: url,
      question_id: props.questions[curQ].question_id,
      question_text: props.questions[curQ].text,
      email: employee?.email!,
      first_name: employee?.first_name!,
      last_name: employee?.last_name!,
      title: employee?.title!,
    };
    testimonialCreate.mutate(t);
  };

  const handleNext = () => {
    if (curQ + 1 === props.questions.length) {
      props.oncomplete();
    } else {
      setCurQ(curQ + 1);
      setIsRecording(false);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {props.questions && props.questions.length > 0 && (
        <Box sx={{ textAlign: 'center' }}>
          <Typography sx={{ pb: 2 }}>{`Question ${curQ + 1}`}</Typography>
          <Typography
            variant="h1"
            sx={{ fontSize: 24, fontWeight: 'bold', pb: 2 }}
          >
            {props.questions[curQ].text}
          </Typography>
          <Typography sx={{ pb: 2 }}>{props.questions[curQ].prompt}</Typography>
          <Divider sx={{ mb: 2 }} />
          <Box
            sx={{
              display: { xs: 'inline', sm: 'flex' },
              justifyContent: 'center',
            }}
          >
            <>
              {!isRecording && (
                <>
                  <FileUpload
                    label="Upload/Import Video"
                    value=""
                    showSuccess={true}
                    changehandler={createTestimonial}
                    uploadDir="uploads"
                    filetypes="video/*"
                    disableDisplay={true}
                  />
                  <Box
                    onClick={() => setIsRecording(true)}
                    sx={{
                      border: 1,
                      borderColor: 'info.main',
                      borderStyle: 'solid',
                      borderRadius: 3,
                      height: 150,
                      minWidth: { xs: 150, sm: 150 },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      p: 5,
                      mb: 2,
                      ml: { xs: 0, sm: 1 },
                      '&:hover': {
                        backgroundColor: 'info.main',
                        color: 'white',
                      },
                    }}
                  >
                    <CustomIcon name="record" />
                    <Typography sx={{ textAlign: 'center' }}>
                      Record Video
                    </Typography>
                  </Box>
                </>
              )}
              {isRecording && (
                <WebRecorder
                  onDelete={() => setIsRecording(false)}
                  onSuccess={(file: string) => createTestimonial(file)}
                />
              )}
            </>
          </Box>
          <Typography
            sx={{
              fontSize: 18,
              position: 'absolute',
              right: '30%',
              cursor: 'pointer',
              pt: 5,
              maxWidth: 100,
            }}
            onClick={handleNext}
          >
            Skip
            <CustomIcon name="next" sx={{ top: 5, position: 'relative' }} />
          </Typography>
        </Box>
      )}
    </>
  );
};
