import React, { useState, useRef } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { CustomIcon, ProgressBar } from '../atoms';
import { S3_BUCKET, AWS_REGION } from '../config/index';
import AWS from 'aws-sdk';
import { toast } from 'react-toastify';

export interface FileUploadProps {
  label: string;
  value: string;
  showSuccess?: boolean;
  changehandler?: (url: string) => void;
  uploadDir?: string;
  filetypes?: 'image/*' | 'video/*';
  disableDisplay?: boolean;
}

export const FileUpload = (props: FileUploadProps): JSX.Element => {
  const [fileURL, setFileURL] = useState<string>(props.value || '');
  const [progress, setProgress] = useState<number>();
  const [grabbing, setGrabbing] = useState<boolean>(false);

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!grabbing) {
      setGrabbing(true);
    }
  };
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!grabbing) {
      setGrabbing(true);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    if (files && files.length) {
      handleFileDrop(files);
    }
  };

  const uploadToS3 = (file: any, filepath: string) => {
    const bucket = new AWS.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: S3_BUCKET },
      region: AWS_REGION,
      httpOptions: {
        timeout: 240000,
      },
    });
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: filepath,
    };
    bucket
      .putObject(params)
      .on('httpUploadProgress', (evt) => {
        let percentLoaded = Math.round((evt.loaded * 100) / evt.total);
        setProgress(percentLoaded);
        if (evt.loaded === evt.total) {
          handleSuccess(filepath);
        }
      })
      .send((err) => {
        if (err) {
          toast.error(String(err));
        }
      });
  };

  const handleSuccess = (filepath: string) => {
    console.log('handle success');
    const fullpath = `https://${S3_BUCKET}/${filepath}`;
    setProgress(undefined);
    if (props.showSuccess) {
      toast('🦄 File saved!');
    }
    if (props.changehandler) {
      props.changehandler(fullpath);
    }
    setTimeout(() => {
      setFileURL(fullpath);
    }, 3000);
  };

  const getFileName = (name: string): string => {
    const filesplit = name.split('.');
    const bname =
      btoa(name + Date.now().toString()) +
      '.' +
      filesplit[filesplit.length - 1];
    return bname;
  };

  const getFilePath = (name: string): string => {
    const filename = getFileName(name);
    const path = props.uploadDir ? `${props.uploadDir}/${filename}` : filename;
    return path;
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filename = e.target.value.split('\\')[2];
    const filepath = getFilePath(filename);
    if (e.target.files) {
      uploadToS3(e.target.files[0], filepath);
    }
  };

  const handleFileDrop = (files: FileList) => {
    const path = getFilePath(files[0].name);
    uploadToS3(files[0], path);
  };

  const fileInputField = useRef<HTMLInputElement>(null);
  return (
    <>
      <Box
        onClick={() => fileInputField?.current?.click()}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        sx={{
          border: 1,
          borderColor: 'info.main',
          borderStyle: 'dashed',
          borderRadius: 3,
          height: 150,
          minWidth: { xs: 150, sm: 150 },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: grabbing ? 'grabbing' : 'pointer',
          p: 5,
          mb: 2,
          '&:hover': {
            backgroundColor: 'info.main',
            color: 'white',
          },
        }}
      >
        <input
          type="file"
          accept={props.filetypes ? props.filetypes : '*'}
          ref={fileInputField}
          title=""
          value=""
          onChange={handleFileUpload}
          style={{ display: 'none' }}
        />
        <CustomIcon name="upload" />
        <Typography sx={{ textAlign: 'center' }}>{props.label}</Typography>
      </Box>
      {progress && <ProgressBar progress={progress} />}
      {fileURL && !props.disableDisplay && (
        <Grid
          id="progress-container"
          sx={{
            pt: 2,
            width: { sx: '50%', md: '100%' },
            backgroundColor: '#EBEBEC',
          }}
        >
          <img
            src={fileURL}
            width="50%"
            alt="file upload"
            crossOrigin="anonymous"
          />
        </Grid>
      )}
    </>
  );
};
